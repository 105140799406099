<template>
  <div>
    <div class="fdBox" v-for="item in firendJson" :id="item.kh">
      <span class="fdInfo1" :style="{ backgroundImage: 'url(' + item.avatar + ')' }">
        <!--头像-->
      </span>
      <span class="fdInfo2">{{ item.name }}</span>
      <span class="fdInfo3"><i :class="[item.sex == 1 ? 'man' : 'woman']"></i> {{ item.age }}</span>
      <span class="fdInfo4" @click="message(item)" style="display:none;">
        <!--消息-->
      </span>
      <span class="fdInfo5">{{ item.act }}</span>
      <span class="fdInfo6">{{ item.actDate }}</span>
    </div>
    <!-- 下面是个新增按钮，目前无用 -->
    <!--  <div class="fixedBar"></div> -->
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "",
    type: "", //查询动态的类型
    props: {},
    data() {
      return {
        firendJson: [

        ],
      };
    },
    components: {},
    mounted() {
      this.getWxConfig(); //加载js
      var url = location.href;
      window.addEventListener("scroll", this.handleScroll);
      this.type = getQueryVariable(url,
        "type");
      this.getAct(this.type); //获取最新的动态
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      async message(item) {
        //消息中心
        this.$router.push({
          path: '/reply',
          query: {
            'userCode': item.kh
          }
        });
      },
      async getAct(type) {
        //获取动态，可以获取全部动态和个人的动态

        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "get_integral_2021",
          user: Cookies.get("kh"),
          queryUser: '', //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          type: type, //1是全部的动态， 2是自己的动态 3是自己关注的会员的动态 4,指定会员动态，后台有权限判断  5，看来访问自己的记录
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //that.firendJson = result.data;
          var data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                avatar: data[i].avatar,
                name: data[i].name,
                age: data[i].age,
                sex: data[i].sex,
                act: data[i].act,
                actDate: data[i].actDate,
                kh: data[i].kh,
              };
              that.firendJson.push(sData);
            }
          }
        } else {
          Toast (result.msg);
        }
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .fdBox {
    height: 159px;
    border-bottom: 1px #f3f3f3 solid;
    width: 100%;
    position: relative;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .fdInfo1 {
      left: 12px;
      top: 20px;
      height: 56px;
      width: 56px;
      border-radius: 30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .fdInfo2 {
      left: 74px;
      top: 20px;
      height: 16px;
      line-height: 16px;
      width: 250px;
      color: #222;
      font-size: 15px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fdInfo3 {
      left: 74px;
      top: 50px;
      height: 12px;
      line-height: 12px;
      width: 56px;
      color: #c72e59;
      font-weight: bold;
      font-size: 12px;

      i {
        display: inline-block;
      }

      .man {
        height: 12px;
        width: 12px;
        background-image: url('../../assets/img/mysex1.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
      }

      .woman {
        height: 12px;
        width: 12px;
        background-image: url('../../assets/img/mysex0.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
      }
    }

    .fdInfo4 {
      right: 20px;
      top: 35px;
      height: 28px;
      width: 28px;
      background-image: url('../../assets/img/msg1.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 28px;
    }

    .fdInfo5 {
      left: 12px;
      top: 90px;
      height: 38px;
      line-height: 20px;

      width: 340px;
      color: #222;
      font-size: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .fdInfo6 {
      left: 12px;
      bottom: 12px;
      height: 12px;
      width: 200px;
      line-height: 12px;
      font-size: 12px;
    }
  }

  .fixedBar {
    position: fixed;
    right: 20px;
    bottom: 80px;
    background-color: white;
    width: 50px;
    height: 50px;
    box-shadow: 2px 2px 10px rgb(199, 46, 89, 0.3);
    border-radius: 40px;
    background-image: url('../../assets/img/add.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }
</style>
